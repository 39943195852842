<template>
    <div class="content_container">
        <h1>Client Login</h1>
        <hr>
        <div class="client_login">
            <div v-for="(l, i) in data" class="login_detail" :key="i">
                <a :href="l.url" target="_blank" rel="noopener noreferrer" :aria-label="`Go to ${l.name} page.`">
                    <img :src="l.img" :alt="l.name">
                </a>
                <a :href="l.url" target="_blank" rel="noopener noreferrer" class="btn btn_login">
                    {{l.name}}
                </a>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "Login",
    data(){
        return{
            data:[
                {
                    name:"Maintenance Request",
                    img:"/images/hammer-solid.svg",
                    url:"https://cerdazein.appfolio.com/connect/users/sign_in",
                },
                {
                    name:"Owner Login",
                    img:"/images/key-solid.svg",
                    url:"https://cerdazein.appfolio.com/portal",
                },
                {
                    name:"Resident Login",
                    img:"/images/user-friends-solid.svg",
                    url:"https://cerdazein.appfolio.com/connect",
                },

            ],
        }
    },
    metaInfo: {
        title: 'Client Login',
        titleTemplate: '%s | Cerda-Zein',
        meta: [{
            name: 'description', 
            content: "Cerda-Zein"
        }]
    },
}
</script>

<style lang="scss" scoped>
h1{
    text-align: center;
    font-family: $secondaryFont;
    font-size: 2.5rem;
    margin-bottom: 0;
    padding-top: 80px;
}
hr{
    width: 300px;
    margin: 32px auto;
    opacity: 0.4;
}
.client_login{
    display:flex;
    justify-content: center;
    margin: 0 32px 64px 32px;
    flex-wrap: wrap;
    .login_detail{
        width: 33.33%;
        text-align: center;
        display:flex;
        flex-direction: column;
        justify-items: center;
        align-items: center;
        img{
            width: 50px;
            height: 50px;
        }
        .btn_login{
            padding: 10.5px 36px;
            width: 250px;
        }
    }
}

@media (max-width: $mobile) {
    .client_login{
        .login_detail{
            width: 100%;
        }
    }
}
</style>
